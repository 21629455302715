"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.init = void 0;
var general_1 = require("../general");
var common_selectors_1 = require("../common_selectors");
var focusedColumn = null;
var toggleDrilldown = function () {
    var _a;
    var activeElement = document.activeElement;
    if (activeElement.id === "drilldown") {
        return;
    }
    var isColumnActive = (_a = activeElement.classList) === null || _a === void 0 ? void 0 : _a.contains("column");
    if (isColumnActive) {
        (0, general_1.setActive)(common_selectors_1.drilldownButton);
        common_selectors_1.drilldownButton.disabled = false;
        focusedColumn = activeElement;
    }
    else {
        focusedColumn = null;
        (0, general_1.setInactive)(common_selectors_1.drilldownButton);
        common_selectors_1.drilldownButton.disabled = true;
    }
};
var init = function () {
    document.addEventListener("focusin", function () {
        toggleDrilldown();
    });
    document.addEventListener("blur", function () {
        toggleDrilldown();
    });
    document.addEventListener("click", function () {
        toggleDrilldown();
    });
    common_selectors_1.drilldownButton.addEventListener("click", function () {
        if (focusedColumn) {
            var arrowNode = document.createElement("div");
            arrowNode.contentEditable = "true";
            arrowNode.className = "drilldown-arrow";
            arrowNode.innerHTML = "⬇";
            var newLineNode = document.createElement("br");
            var container = document.createElement("div");
            container.appendChild(newLineNode);
            container.appendChild(arrowNode);
            container.appendChild(newLineNode);
            var selection = window.getSelection();
            var range = selection.getRangeAt(0);
            range.insertNode(container);
            range.setStartAfter(container);
            range.setEndAfter(container);
            selection.removeAllRanges();
            selection.addRange(range);
            focusedColumn.focus();
        }
    });
};
exports.init = init;
