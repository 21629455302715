"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLoader = void 0;
var common_selectors_1 = require("./common_selectors");
var defaultLoader = document.querySelector('.main-loader');
var useLoader = function (loader) {
    if (loader === void 0) { loader = defaultLoader; }
    var html = document.getElementsByTagName('html')[0];
    return {
        show: function () {
            var isLoaderShowing = !loader.classList.contains('hidden-loader');
            if (isLoaderShowing)
                return;
            loader.classList.remove('hidden-loader');
            common_selectors_1.inventorySection.classList.add('animate', 'hidden-loader');
            common_selectors_1.inventorySection.innerHTML = '';
            common_selectors_1.body.classList.add('no-overflow');
            html.classList.add('no-overflow');
        },
        hide: function () {
            loader.classList.add('hidden-loader');
            common_selectors_1.inventorySection.classList.remove('hidden-loader');
            common_selectors_1.body.classList.remove('no-overflow');
            html.classList.remove('no-overflow');
        },
    };
};
exports.useLoader = useLoader;
