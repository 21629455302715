"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.init = exports.checkSaveButtonState = void 0;
var globalState_1 = require("../../states/globalState");
var loginState_1 = require("../../states/loginState");
var tableState_1 = require("../../states/tableState");
var general_1 = require("../general");
var common_selectors_1 = require("../common_selectors");
var onSaveTable = function () {
    globalState_1.globalState.current = globalState_1.globalState.allowedStates.tagging;
};
var checkSaveButtonState = function () {
    var isEditingTable = globalState_1.globalState.isEditing() && tableState_1.tableState.isDirty();
    if (loginState_1.loginState.isAuthorized() && isEditingTable) {
        (0, general_1.setActive)(common_selectors_1.saveButton);
    }
    else {
        (0, general_1.setInactive)(common_selectors_1.saveButton);
    }
};
exports.checkSaveButtonState = checkSaveButtonState;
var init = function () {
    var windowHeight = global.innerHeight;
    (0, general_1.addEventListenerToList)([common_selectors_1.saveButton], "click", onSaveTable);
    global.visualViewport.addEventListener("resize", function () {
        if (windowHeight < global.innerHeight) {
            windowHeight = global.innerHeight;
            common_selectors_1.drilldownButton.style.bottom = "20px";
            common_selectors_1.newTableButton.style.bottom = "20px";
        }
        else {
            windowHeight = global.innerHeight;
            common_selectors_1.drilldownButton.style.bottom = "10%";
            common_selectors_1.newTableButton.style.bottom = "10%";
        }
    });
    tableState_1.tableState.registerListener({
        name: "initButtons",
        listener: function () {
            var isEditingTable = globalState_1.globalState.isEditing() && tableState_1.tableState.isDirty();
            isEditingTable ? (0, general_1.setActive)(common_selectors_1.newTableButton) : (0, general_1.setInactive)(common_selectors_1.newTableButton);
            (0, exports.checkSaveButtonState)();
        },
    });
};
exports.init = init;
