"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tagsInput = exports.tagsSection = exports.tableSwitches = exports.allColumns = exports.visibleColumns = exports.inventorySection = exports.drilldownButton = exports.newTableButton = exports.saveButton = exports.inventoryButton = exports.body = void 0;
exports.body = document.getElementById("body");
exports.inventoryButton = document.getElementById("archive");
exports.saveButton = document.getElementById("save-table");
exports.newTableButton = document.getElementById("new-table");
exports.drilldownButton = document.getElementById("drilldown");
exports.inventorySection = document.getElementById("inventory");
exports.visibleColumns = document.getElementsByClassName("column visible");
exports.allColumns = document.querySelectorAll("#table-columns .column");
exports.tableSwitches = document.querySelectorAll("#menu .table");
exports.tagsSection = document.getElementById("tags-container");
exports.tagsInput = document.getElementById("tag-name-input");
