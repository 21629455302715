"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cleanTable = exports.initTable = exports.tableState = exports.cleanPage = exports.toggleSwitches = void 0;
var general_1 = require("../general");
var globalState_1 = require("../../states/globalState");
var tableState_1 = require("../../states/tableState");
Object.defineProperty(exports, "tableState", { enumerable: true, get: function () { return tableState_1.tableState; } });
var buttons_1 = require("./buttons");
var useSaveTable_1 = require("../useSaveTable");
var common_selectors_1 = require("../common_selectors");
var drilldown_1 = require("./drilldown");
var showRelevantTableSize = function (event) {
    var columnCount = event.currentTarget.dataset.columnCount;
    var firstColumnSet = false;
    Array.from(common_selectors_1.allColumns).forEach(function (column) {
        column.classList.remove('first');
        if (column.classList.contains(columnCount)) {
            column.classList.remove('hidden');
            column.classList.add('visible');
            if (!firstColumnSet) {
                column.classList.add('first');
                firstColumnSet = true;
            }
        }
        else {
            column.classList.remove('visible');
            column.classList.add('hidden');
        }
    });
    globalState_1.globalState.current = globalState_1.globalState.allowedStates.editing;
};
var toggleTableState = function () {
    var nonHiddenColumns = Array.from(common_selectors_1.allColumns).filter(function (column) { return !column.classList.contains('hidden'); });
    var nonEmptyColumns = nonHiddenColumns.filter(function (column) { return column.textContent.length; });
    if (nonEmptyColumns.length) {
        tableState_1.tableState.current = tableState_1.tableState.allowedStates.dirty;
    }
    else {
        tableState_1.tableState.current = tableState_1.tableState.allowedStates.clean;
    }
};
var toggleSwitchesEvent = function (e) {
    (0, exports.toggleSwitches)(e.currentTarget);
};
var toggleSwitches = function (switchToActivate) {
    common_selectors_1.tableSwitches.forEach(function (element) { (0, general_1.setInactive)(element); });
    (0, general_1.setActive)(switchToActivate);
};
exports.toggleSwitches = toggleSwitches;
var cleanPage = function () {
    common_selectors_1.allColumns.forEach(function (column) {
        column.innerHTML = '';
    });
};
exports.cleanPage = cleanPage;
var cleanTable = function () {
    (0, exports.cleanPage)();
    (0, general_1.setInactive)(common_selectors_1.newTableButton.querySelector('svg'));
    (0, general_1.setInactive)(common_selectors_1.saveButton);
    (0, general_1.setInactive)(common_selectors_1.drilldownButton);
    delete globalState_1.globalState.data.editedTableId;
    tableState_1.tableState.current = tableState_1.tableState.allowedStates.clean;
};
exports.cleanTable = cleanTable;
var onTableSwitchClick = function (event) {
    showRelevantTableSize(event);
    toggleTableState();
    toggleSwitchesEvent(event);
};
var initTable = function () {
    (0, general_1.addEventListenerToList)(common_selectors_1.tableSwitches, 'click', onTableSwitchClick);
    (0, general_1.addEventListenerToList)(common_selectors_1.allColumns, 'input', toggleTableState);
    common_selectors_1.newTableButton.addEventListener('click', cleanTable);
    (0, buttons_1.init)();
    tableState_1.tableState.registerListener({
        name: 'cleanTable',
        listener: function () {
            if (tableState_1.tableState.isDirtySinceSubmit()) {
                cleanTable();
            }
        },
    });
    (0, useSaveTable_1.useSaveTable)([function () {
            tableState_1.tableState.current = tableState_1.tableState.allowedStates.dirtySinceSubmit;
        }]);
    (0, drilldown_1.init)();
};
exports.initTable = initTable;
